"use client";

import {
  BlogCard,
  Button,
  Chip,
  Column,
  Flex,
  Heading,
  Icon,
  ImageWrapper,
  RichText,
  Row,
  Separator,
  ShareModal,
  Slider,
  Spacer,
} from "oddsgate-ds";
import { IPost, Taxonomy } from "@/lib/wordpress/_types/archiveGrid";
import { Suspense, useEffect, useState } from "react";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import NavigationEvents from "@/components/common/NavigationEvents/NavigationEvents";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { formatDate } from "@/lib/helpers/dateFormate";
import { removeScroll } from "@/lib/helpers/scrollHelpers";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledPost = styled.div`
  /* display: block;

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  & > p {
    margin-bottom: 1.6rem;
  }

  & > div {
    margin-bottom: 1.6rem;
  }

  & > picture {
    margin-bottom: 2rem;
  }

  & > hr {
    margin-bottom: 2rem;
  }

  & .container {
    padding-left: 0;
    padding-right: 0;
  }

  .column {
    & > picture {
      max-width: 100% !important;
    }
  }

  & a {
    min-width: auto;
  }

  & p a,
  & li a {
    color: $secondary-color;

    &:hover {
      opacity: 0.8;
    }
  } */
`;

const BlogPost = (post: IPost) => {
  const state = useGlobalContext();
  const [shareOpen, setShareOpen] = useState(false);

  useEffect(() => {
    removeScroll(shareOpen, state);
  }, [shareOpen, state]);

  const categories = post?.categories?.filter((c) => c.name === "category")[0]?.items as unknown as Taxonomy[] || [];

  const relatedArticles = post?.acf?.related_articles;
  const newsletterBlock = post?.newsletterBlock;

  return (
    <div>
      <Suspense>
        <NavigationEvents translatedURI={post?.translatedURI} acf={{ page_style: "light", pageTitle: `${state?.dict?.post_types?.blog}: ${post.title}` }} />
      </Suspense>

      <Spacer height={8} />
      <Row className="text-center">
        <Column>
          <Heading tag="h1" size="h1">
            {post.title}
          </Heading>
          <Spacer height={5} />
          <ImageWrapper aspectRatioHeight={48}>
            <DisplayImage
              alt={post?.title as string}
              url={post?.image as string}
              fill={true}
            />
          </ImageWrapper>
        </Column>
      </Row>

      <Spacer height={5} />
      <Row align="center" mobileSpace={true}>
        <Column md={8} className="text-center text-md-start">
          {categories && categories?.map((item, i) => {
            return <Chip key={`category-${i}`} variant='light' className='font-epilogue'>{item.name}</Chip>
          })}
        </Column>
        <Column md={4} className="text-center text-md-end">
          <Heading tag="span" size="h4" className="fw-bold text-uppercase">
            {formatDate(post?.date as string, "DATE_SHORT")}
          </Heading>
        </Column>
      </Row>

      <Spacer height={10} />

      <StyledPost>
        {!!(post?.blocks as [])?.length &&
          (post?.blocks as []).map((block: BlocksProps, index: number) => {
            return displayBlock(block, index);
          })}
      </StyledPost>

      <Spacer height={6} />

      <Row>
        <Column className={"text-center"}>
          <Button
            variant={"secondary"}
            mode="dark"
            rightIcon={<Icon icon="icon-arrow-right" />}
            onClick={() => setShareOpen(true)}
          >
            {(state?.dict?.blog as Record<string, string>).shareBtn}
          </Button>
        </Column>
      </Row>

      <Spacer height={11} />


      {relatedArticles && relatedArticles.length > 0 && (
        <Row>
          <Column md={12}>
            <Heading tag="h4" size="h1" className="color-primary50 text-uppercase fw-bold">
              {state?.dict?.blog?.related_articles}
            </Heading>
            <Spacer height={6} />
            <Slider
              arrows={false}
              dots={true}
              slidesToShow={3}
              infinite={true}
              autoplay={true}
              mediaQueries={{
                900: {
                  slidesToShow: 2,
                  dots: true,
                },
                500: {
                  slidesToShow: 1
                }
              }}
            >
              {relatedArticles.map((related: IPost, i: number) => {
                const category = related?.categories as unknown as Taxonomy[] || [];
                const categoryName = category && Object.keys(category).length > 0 ? category[0]?.items[0]?.name : "";

                const imageElement = (
                  <DisplayImage
                    url={related?.image as string}
                    alt={related?.title ? (related?.title as string) : ""}
                    fill={true}
                  />
                );

                return (
                  <div key={`related-${i}`}>
                    <BlogCard
                      variant={"featured"}
                      category={categoryName}
                      imageElement={imageElement}
                      title={related.title}
                      description={related.excerpt}
                      author={related.author}
                      linkElement={
                        <Link
                          href={related.slug as string}
                          aria-label={`Read more about ${related.title}`}
                          role="link"
                          className="linker"
                        ></Link>
                      }
                    />
                  </div>
                );
              })}
            </Slider>
          </Column>
        </Row>
      )}

      <div>
        {!!(newsletterBlock?.blocks as [])?.length &&
          (newsletterBlock?.blocks as []).map((block: BlocksProps, index: number) => {
            return displayBlock(block, index);
          })}
      </div>

      <ShareModal
        siteTitle={process.env.NEXT_PUBLIC_SITE_NAME}
        title={post.title}
        url={`${process.env.NEXT_PUBLIC_FE_URL}${post.slug}`}
        label={(state?.dict?.blog as Record<string, string>).shareBtn}
        copyText={(state?.dict?.blog as Record<string, string>).shareCopy}
        open={shareOpen}
        onClose={() => setShareOpen(false)}
      ></ShareModal>
    </div>
  );
};
export default BlogPost;
